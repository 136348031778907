import React, { useState } from "react";
import data from "../data/data.js";

const titles = ["NO", "СТАНДАРТ", "DN", "L(±2)", "K(±2)", "T(мм)",/*" ҮНЭ" */  ]; // Fixed titles array

const miniTableTitles = [
  { label: "Базах хэмжээ", key: "size" },
  { label: "Базах хүч", key: "power" },
  { label: "Жин", key: "weight" },
  { label: "Урт", key: "lenght" },
  { label: "Өндөр", key: "height" },
  { label: "Өргөн", key: "width" },
  { label: "Баттерей", key: "battery" },
  { label: "Базах хугацаа", key: "time" },
]; // Mini table titles

const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [overlayImage, setOverlayImage] = useState(null);

  const handleProductSelect = (productId) => {
    setSelectedProduct(selectedProduct === productId ? null : productId);
    setSelectedFeature(null); // Reset feature selection when a different product is selected
  };

  const handleFeatureSelect = (featureId) => {
    setSelectedFeature(selectedFeature === featureId ? null : featureId);
  };

  const handleImageClick = (imageUrl) => {
    setOverlayImage(imageUrl);
  };

  const closeOverlay = () => {
    setOverlayImage(null);
  };

  const formatPrice = (price) => {
    return price.toLocaleString();
  };

  return (
    <div className="flex flex-col lg:flex-row">
      {/* Product List */}
      <div className="flex-1 overflow-auto">
        {data.map((product) => (
          <div key={product.id} className="mb-3">
            {/* Product Name */}
            <div
              className={`cursor-pointer ${
                selectedProduct === product.id
                  ? "md:font-semibold text-lg shadow-md uppercase border-b-2 border-red-500 lg:px-2 "
                  : "md:font-semibold text-lg shadow-md uppercase px-2 "
              }`}
              onClick={() => handleProductSelect(product.id)}
            >
              {product.name}
            </div>

            {/* Feature List */}
            {selectedProduct === product.id && (
              <div className="lg:ml-4">
                {product.features.map((feature, index) => (
                  <div key={index}>
                    <div
                      onClick={() => handleFeatureSelect(index)}
                      className={`cursor-pointer ${
                        selectedFeature === index
                          ? "md:font-bold text-base border-b-2 uppercase border-red-500 px-2 mt-1"
                          : "text-base md:font-semibold px-2 uppercase mt-1"
                      }`}
                    >
                      {feature.name}
                    </div>

                    {/* Mobile table */}
                    {selectedFeature === index && (
                      <div className="lg:hidden py-2 rounded">
                        <div className="feature-image flex justify-center bg-white mb-2">
                          <img
                            src={feature.imageUrl}
                            alt={feature.name}
                            className="max-w-[120px] max-h-[120px] object-cover rounded"
                          />
                        </div>
                        <div className="feature-specifications">
                          <div className="text-sm overflow-x-auto">
                            <table className="min-w-full bg-white border border-gray-200">
                              <thead>
                                <tr className="bg-gray-200">
                                  {titles
                                    .filter((title) => title !== "СТАНДАРТ")
                                    .map(
                                      (
                                        title,
                                        index // Filter out "СТАНДАРТ"
                                      ) => (
                                        <th
                                          key={index}
                                          className="px-1 py-[1px] border-b border-gray-200 text-left text-sm font-medium text-gray-700"
                                        >
                                          {title}
                                        </th>
                                      )
                                    )}
                                </tr>
                              </thead>
                              <tbody>
                                {feature.specifications.map((spec) => (
                                  <tr
                                    key={spec.id}
                                    className="hover:bg-gray-100"
                                  >
                                    <td className="px-1 py-2 border-b text-sm text-gray-600">
                                      {spec.id}
                                    </td>
                                    {/* Remove the cell for "СТАНДАРТ" */}
                                    <td className="px-1 py-2 border-b text-sm text-gray-600">
                                      {spec.dn}
                                    </td>
                                    <td className="px-1 py-2 border-b text-sm text-gray-600">
                                      {spec.L}
                                    </td>
                                    <td className="px-1 py-2 border-b text-sm text-gray-600">
                                      {spec.K}
                                    </td>
                                    <td className="px-1 py-2 border-b text-sm text-gray-600">
                                      {spec.T}
                                    </td>
                                    {/* <td className="px-1 py-2 border-b text-sm text-gray-600">
                                      {formatPrice(spec.price)}₮
                                    </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Feature Details (Image and Specifications) */}
      <div className="w-2/3 hidden lg:block pl-8 ">
        {selectedFeature !== null && (
          <div>
            {/* Image Section */}
            <div className="flex justify-evenly mb-4">
              <div
                className="max-h-44 max-w-44 flex items-center justify-center cursor-pointer"
                onClick={() =>
                  handleImageClick(
                    data[selectedProduct - 1].features[selectedFeature].imageUrl
                  )
                }
              >
                <img
                  src={
                    data[selectedProduct - 1].features[selectedFeature].imageUrl
                  }
                  alt={data[selectedProduct - 1].features[selectedFeature].name}
                  className="max-h-full max-w-full"
                />
              </div>

              {data[selectedProduct - 1].features[selectedFeature]
                .imageUrl1 && (
                <div
                  className="max-h-44 max-w-44 flex items-center justify-center cursor-pointer"
                  onClick={() =>
                    handleImageClick(
                      data[selectedProduct - 1].features[selectedFeature]
                        .imageUrl1
                    )
                  }
                >
                  <img
                    src={
                      data[selectedProduct - 1].features[selectedFeature]
                        .imageUrl1
                    }
                    alt={
                      data[selectedProduct - 1].features[selectedFeature].name
                    }
                    className="max-h-36 w-auto" // Adjust height as needed
                  />
                </div>
              )}
            </div>

            {/* Specifications Table */}
            <div>
              <table className="min-w-full border border-gray-200">
                <thead>
                  <tr className="bg-gray-400">
                    {titles.map((title, index) => (
                      <th
                        key={index}
                        className="px-2 py-2 border-b text-center"
                      >
                        {title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data[selectedProduct - 1].features[
                    selectedFeature
                  ].specifications.map((spec, index) => (
                    <tr
                      key={spec.id}
                      className={index % 2 === 0 ? "" : "bg-gray-200"}
                    >
                      <td className="px-1 py-2 border-b text-base  text-center">
                        {spec.id}
                      </td>
                      <td className="px-1 py-2 border-b text-base font-semibold text-center">
                        {spec.standart}
                      </td>
                      <td className="px-1 py-2 border-b text-base  font-semibold text-center">
                        {spec.dn}
                      </td>
                      <td className="px-1 py-2 border-b text-base  font-semibold text-center">
                        {spec.L}
                      </td>
                      <td className="px-1 py-2 border-b text-base font-semibold text-center">
                        {spec.K}
                      </td>
                      <td className="px-1 py-2 border-b text-base  font-semibold text-center">
                        {spec.T}
                      </td>
                      {/* <td className="px-1 py-2 border-b text-base  font-semibold text-center">
                        {formatPrice(spec.price)}₮
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              {selectedFeature !== null &&
                data[selectedProduct - 1].features[
                  selectedFeature
                ].specifications.some((spec) => spec.morespec) && (
                  <div className="mt-4">
                    <table className="min-w-full border border-gray-200">
                      <tbody>
                        {miniTableTitles.map((title, index) => (
                          <tr key={index}>
                            <td className="px-2 py-1 border-b">
                              {title.label}:
                            </td>
                            <td className="px-2 py-1 border-b">
                              {data[selectedProduct - 1].features[
                                selectedFeature
                              ].specifications.map(
                                (spec) =>
                                  spec.morespec &&
                                  spec.morespec.map((more, idx) => (
                                    <div key={idx}>
                                      {more[title.key] || "N/A"}
                                    </div>
                                  ))
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>

      {/* Overlay for Enlarged Image */}
      {overlayImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeOverlay}
        >
          <div className="relative">
            <img
              src={overlayImage}
              alt="Enlarged view"
              className="max-h-screen max-w-screen"
            />
            <button
              className="absolute top-0 right-0 text-white text-3xl p-2"
              onClick={closeOverlay}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
