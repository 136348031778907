import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Slider = ({ slides }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="max-h-screen mt-18 lg:relative lg:mt-0 md:relative md:mt-0"
    >
      {slides.map((slide, idx) => (
        <Carousel.Item key={idx} interval={slide.interval}>
          <img
            className="object-contain object-center w-full mt-14 h-auto sm:h-96 md:h-96 lg:h-[600px] md:object-cover md:mt-0"
            src={slide.image}
            alt={`Slide ${idx + 1}`}
            style={{ objectPosition: "center center" }} // Adjust object position as needed
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default Slider;
